.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f0f2f5;
}

.login-title {
    font-size: 2rem;
    margin-bottom: 1rem;
}

.login-form {
    background-color: #ffffff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
}

.Login-form-group {
    margin-bottom: 1rem;
}

.form-group label {
    display: block;
    font-size: 1rem;
    margin-bottom: 0.5rem;
}

.login-form-control {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
}

.login-button {
    width: 100%;
    padding: 0.75rem;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    color: white;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.2s;
}

    .login-button:hover {
        background-color: #0056b3;
    }

.login-error {
    color: #e74c3c;
    margin-bottom: 1rem;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    width: 400px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.modal-form-group {
    margin-bottom: 15px;
}

    .modal-form-group label {
        display: block;
        margin-bottom: 5px;
    }

    .modal-form-group input {
        width: 100%;
        padding: 8px;
        margin: 5px 0;
    }

.reset-password-button {
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    width: 100%;
    cursor: pointer;
}

.close-modal-button {
    margin-top: 10px;
    padding: 10px;
    background-color: #ccc;
    border: none;
    cursor: pointer;
    width: 100%;
}
.error-message {
    color: red;
    font-size: 12px;
}

.success-message {
    color: green;
    font-size: 14px;
}

.reset-password-button:disabled {
    background-color: gray;
    cursor: not-allowed;
}