.services-container {
    padding: 40px;
    text-align: center;
    max-width: 1000px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
}

    .services-container h1 {
        font-size: 2.5rem;
        margin-bottom: 20px;
    }

    .services-container p {
        font-size: 1.2rem;
        margin-bottom: 40px;
        line-height: 1.6;
    }

.services-cards {
    display: flex;
    justify-content: center;
    gap: 30px;
    margin-top: 20px;
    flex-wrap: wrap; /* Allow cards to wrap on smaller screens */
}

/* Card Styles */
.servicescard {
    width: 300px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    text-align: left;
    margin-bottom: 30px; /* Adds space between cards when they stack */
}

    .servicescard:hover {
        transform: translateY(-10px); /* Slight lift effect on hover */
    }

    .servicescard h2 {
        font-size: 2rem;
        color: #333;
        margin-bottom: 20px;
    }

    .servicescard p {
        font-size: 1rem;
        margin-bottom: 20px;
    }

    .servicescard ul {
        list-style-type: none;
        padding: 1;
        margin-bottom: 20px;
    }

        .servicescard ul li {
            font-size: 1rem;
            margin-bottom: 10px;
        }

.cta-btn {
    width: 100%; /* Makes the button fill the available width */
    padding: 12px; /* Set padding to make buttons a consistent height */
    font-size: 1rem;
    color: white;
    background-color: #007BFF;
    border: none;
    border-radius: 8px; /* Rounded corners for a card-like appearance */
    cursor: pointer;
    text-decoration: none;
    text-align: center; /* Ensures text is centered in the button */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adding soft shadow to mimic depth */
    transition: all 0.3s ease; /* Smooth transition effect on hover */
    margin-top: 10px; /* Adding space above the button for better layout */
}

    /* Hover effect */
    .cta-btn:hover {
        background-color: #0056b3; /* Darker blue on hover */
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
        transform: translateY(-4px); /* Lift the button slightly on hover */
    }

    /* Focus effect */
    .cta-btn:focus {
        outline: none; /* Remove the default focus outline */
        box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5); /* Outline effect on focus */
    }
/* Media query for smaller screens */
@media (max-width: 768px) {
    .services-cards {
        flex-direction: column; /* Stack the cards vertically */
        align-items: center; /* Center the cards horizontally */
    }

    .servicescard {
        width: 80%; /* Make the cards take up more width on small screens */
        max-width: 400px; /* Set a max width for the cards */
    }
}
