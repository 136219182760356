.about-container {
    padding: 40px;
    text-align: center;
    max-width: 900px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
}

    .about-container h1 {
        font-size: 2.5rem;
        margin-bottom: 20px;
    }

    .about-container p {
        font-size: 1.2rem;
        margin-bottom: 20px;
        line-height: 1.6;
    }

    .about-container h2 {
        font-size: 2rem;
        margin-top: 30px;
        margin-bottom: 15px;
    }
