/* Analysis Container */
.analysis-container {
    font-family: Arial, sans-serif;
    background-color: #f4f4f4;
    margin: 0;
    padding: 20px;
    display: flex; /* Enable flexbox */
    align-items: center; /* Center vertically */
    justify-content: center; /* Center horizontally */
    height: 100vh; /* Full height of the viewport */
    text-align: center; /* Center text */
}

/* Title Styles */
.analysis-title {
    color: #333;
}

/* Subtitle Styles */
.analysis-subtitle {
    color: #444;
    margin-top: 20px;
}

.button-container {
    display: flex;
    justify-content: space-between; /* Space out buttons evenly */
    margin: 20px 0; /* Add margin above and below the buttons */
}

.analysis-button {
    width: -webkit-fill-available;
    position: relative;
    background: linear-gradient(135deg, #28a745, #007bff); /* Green to Blue gradient */
    color: white; /* White text for contrast */
    border: none; /* No border */
    border-radius: 5px; /* Rounded corners */
    padding: 20px 40px; /* Larger padding to make button bigger */
    font-size: 24px; /* Increase font size */
    cursor: pointer; /* Pointer cursor on hover */
    transition: all 0.3s ease; /* Smooth transition for hover effects */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    overflow: hidden; /* Ensure glitter stays within the button */
}

    .analysis-button:hover {
        background-color: #0056b3; /* Darker shade on hover */
    }

/* Glitter Effect */
.analysis-button::before,
.analysis-button::after,
.analysis-button .glitter-1,
.analysis-button .glitter-2,
.analysis-button .glitter-3,
.analysis-button .glitter-4 {
    content: '';
    position: absolute;
    background: radial-gradient(circle, rgba(255, 255, 255, 0.8) 20%, rgba(255, 255, 255, 0) 50%);
    border-radius: 50%;
    width: 15px; /* Increase glitter size */
    height: 15px; /* Increase glitter size */
    animation: glitter 1.5s linear infinite;
    pointer-events: none; /* Prevent interaction with glitter */
}

/* Positioning each glitter */
.analysis-button::before {
    top: 10%;
    left: 15%;
    animation-delay: 0s;
}

.analysis-button::after {
    top: 40%;
    left: 60%;
    animation-delay: 0.5s;
}

.analysis-button .glitter-1 {
    top: 30%;
    left: 25%;
    animation-delay: 0.10s;
}

.analysis-button .glitter-2 {
    top: 70%;
    left: 75%;
    animation-delay: 0.30s;
}

.analysis-button .glitter-3 {
    top: 20%;
    left: 80%;
    animation-delay: 0.50s;
}

.analysis-button .glitter-4 {
    top: 50%;
    left: 20%;
    animation-delay: 1s;
}

/* Glitter Animation */
@keyframes glitter {
    0% {
        transform: scale(0.5) translateX(0) translateY(0);
        opacity: 1;
    }

    50% {
        transform: scale(1) translateX(30px) translateY(-30px);
        opacity: 0.7;
    }

    100% {
        transform: scale(0.5) translateX(0) translateY(0);
        opacity: 1;
    }
}

/* Hover effect */
.analysis-button:hover {
    background: linear-gradient(135deg, #218838, #0056b3);
    transform: scale(1.05);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
}

/* Focus effect */
.analysis-button:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
}
/* Form Group Styles */
.analysis-form-group {
    margin: 15px 0;
}

/* Label Styles */
.analysis-label {
    margin-right: 10px;
    font-weight: bold;
}

/* Input Styles */
.analysis-input-date {
    padding: 5px;
    border-radius: 4px;
    border: 1px solid #ccc; /* Light border */
}

/* Chart Container Styles */
.analysis-chart-container {
    border: 1px solid #ccc; /* Light border */
    border-radius: 8px; /* Rounded corners */
    padding: 15px;
    margin: 20px 0;
    background-color: #ffffff; /* White background */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    width: 100%; /* Full width */
    max-width: 600px; /* Max width for better presentation */
    position: relative; /* Needed for absolute positioning of child elements */
}

/* Standard Deviation Label Styles */
.std-dev-label {
    position: absolute; /* Optional: Adjust for positioning */
    top: 10px; /* Distance from the top */
    right: 10px; /* Distance from the right */
    background-color: rgba(255, 255, 255, 0.8); /* Background for readability */
    padding: 5px; /* Padding for better appearance */
    border-radius: 4px; /* Rounded corners */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Shadow for depth */
}

/* Adjust bar styles as necessary */
.analysis-chart-bar-fill {
    height: 20px; /* Example height */
    transition: width 0.3s ease; /* Smooth transition */
}

/* Chart Bar Styles */
.analysis-chart-bar {
    margin: 5px 0;
    display: flex;
    align-items: center; /* Center items vertically */
}

/* Bar Fill Styles */
.analysis-chart-bar-fill {
    background-color: blue; /* Bar color */
    height: 20px; /* Bar height */
    border-radius: 4px; /* Rounded corners */
    transition: width 0.3s ease; /* Smooth transition for width */
}

/* Bar Label Styles */
.analysis-chart-bar-label {
    margin: 0 10px; /* Consistent margin */
    color: #555; /* Gray color */
    font-weight: bold;
}

/* Bar Value Styles */
.analysis-chart-bar-value {
    color: black; /* Adjust color as needed */
    font-weight: bold; /* Bold text */
}
.report-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.report {
    width: 45%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: auto; /* Add scroll if content overflows */
}

.analysis-chart-container {
    margin-top: 20px;
}

.analysis-chart-bar {
    display: flex;
    align-items: center;
    margin: 5px 0;
}

.analysis-chart-bar-fill {
    height: 20px;
    background-color: lightblue;
    margin-left: 10px;
    transition: width 0.3s ease;
}

.analysis-chart-bar-label {
    width: 150px;
}

.analysis-chart-bar-value {
    margin-left: 10px;
}
@media (max-width: 480px) {
    .analysis-button {
        width: -webkit-fill-available;
        position: relative;
        background: linear-gradient(135deg, #28a745, #007bff); /* Green to Blue gradient */
        color: white; /* White text for contrast */
        border: none; /* No border */
        border-radius: 5px; /* Rounded corners */
        padding: 10px 20px; /* Larger padding to make button bigger */
        font-size: 12px; /* Increase font size */
        cursor: pointer; /* Pointer cursor on hover */
        transition: all 0.3s ease; /* Smooth transition for hover effects */
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
        overflow: hidden; /* Ensure glitter stays within the button */
    }
    .button-container {
        display: flex;
        justify-content: space-between; /* Space out buttons evenly */
        margin: 0px 0; /* Add margin above and below the buttons */
    }

   
        .button-container {
            flex-direction: column; /* Stack buttons vertically */
            align-items: stretch; /* Make buttons full width */
        }

        .analysis-button {
            margin: 10px 0; /* Add vertical margin between buttons */
            width: 100%; /* Make buttons full width */
        }
    }