.inventory-system {
    background: #f0f2f5;
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.1);
    width: 80%; /* Default width for larger screens */
    margin: 0 auto; /* Center the element */
    padding: 20px; /* Add padding for better spacing */
    box-sizing: border-box; /* Include padding in width calculations */
}

h1 {
    text-align: center;
    background-blend-mode: linear-gradient(135deg, #28a745, #007bff);
}

form {
    margin-bottom: 20px; /* Space below the form */
    padding: 20px; /* Add some padding inside the form */
    box-sizing: border-box; /* Include padding in width calculations */
}

.card-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 4 cards per row */
    gap: 15px; /* Smaller gap between cards */
    padding: 15px; /* Overall padding around the container */
}

.card {
    padding: 15px;
    margin: 0;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    background-color: white;
    height: auto; /* Let the card height adjust based on content */
    overflow: hidden;
    font-size: 1rem;
    justify-content: space-between;
    flex: 1 1 calc(60% - 10px); /* This ensures the cards take up 50% of the container width minus the gap */
    box-sizing: border-box;
}

    .card.critical {
        background-color: black;
        color: white;
    }

    .card.warning {
        background-color: lightyellow;
        color: black;
    }

    .card.normal {
        background-color: white;
        color: black;
    }

    .card h3 {
        margin-top: 0;
        font-size: 1.5rem; /* Prominent font size */
        font-style: italic;
        font-weight: bold; /* Makes the title stand out */
        background-color: orange; /* Refined orange shade */
        color: #fff; /* White text for contrast */
        padding: 5px 10px; /* Reduced padding for a smaller background */
        border-radius: 5px; /* Rounded corners */
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
        text-align: center; /* Center the text */
        text-transform: capitalize; /* Capitalizes the first letter of each word */
        letter-spacing: 0.5px; /* Slight spacing between letters for clarity */
    }

    .card button {
        margin-top: 8px;
    }

.invadd-button-container {
    display: flex; /* Use flexbox for easy centering */
    justify-content: center; /* Center button horizontally */
    align-items: center; /* Center button vertically */
    height: 100vh; /* Full viewport height */
    width: 100%; /* Full width */
    position: absolute; /* Position it relative to the whole page */
    top: 0;
    left: 0;
}

.invsubmit-button {
    width: -webkit-fill-available;
    position: relative;
    background: linear-gradient(135deg, #28a745, #007bff); /* Green to Blue gradient */
    color: white; /* White text for contrast */
    border: none; /* No border */
    border-radius: 5px; /* Rounded corners */
    padding: 20px 40px; /* Larger padding to make button bigger */
    font-size: 24px; /* Increase font size */
    cursor: pointer; /* Pointer cursor on hover */
    transition: all 0.3s ease; /* Smooth transition for hover effects */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    overflow: hidden; /* Ensure glitter stays within the button */
}

    /* Glitter Effect */
    .invsubmit-button::before,
    .invsubmit-button::after,
    .invsubmit-button .glitter-1,
    .invsubmit-button .glitter-2,
    .invsubmit-button .glitter-3,
    .invsubmit-button .glitter-4 {
        content: '';
        position: absolute;
        background: radial-gradient(circle, rgba(255, 255, 255, 0.8) 20%, rgba(255, 255, 255, 0) 50%);
        border-radius: 50%;
        width: 15px; /* Increase glitter size */
        height: 15px; /* Increase glitter size */
        animation: glitter 1.5s linear infinite;
        pointer-events: none; /* Prevent interaction with glitter */
    }

    /* Positioning each glitter */
    .invsubmit-button::before {
        top: 10%;
        left: 15%;
        animation-delay: 0s;
    }

    .invsubmit-button::after {
        top: 40%;
        left: 60%;
        animation-delay: 0.5s;
    }

    .invsubmit-button .glitter-1 {
        top: 30%;
        left: 25%;
        animation-delay: 0.10s;
    }

    .invsubmit-button .glitter-2 {
        top: 70%;
        left: 75%;
        animation-delay: 0.30s;
    }

    .invsubmit-button .glitter-3 {
        top: 20%;
        left: 80%;
        animation-delay: 0.50s;
    }

    .invsubmit-button .glitter-4 {
        top: 50%;
        left: 20%;
        animation-delay: 1s;
    }

/* Glitter Animation */
@keyframes glitter {
    0% {
        transform: scale(0.5) translateX(0) translateY(0);
        opacity: 1;
    }

    50% {
        transform: scale(1) translateX(30px) translateY(-30px);
        opacity: 0.7;
    }

    100% {
        transform: scale(0.5) translateX(0) translateY(0);
        opacity: 1;
    }
}

/* Hover effect */
.invsubmit-button:hover {
    background: linear-gradient(135deg, #218838, #0056b3);
    transform: scale(1.05);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
}

/* Focus effect */
.invsubmit-button:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
}

.inv-button-container {
    display: flex;
    flex-wrap: wrap; /* Allow wrapping when the screen is smaller */
    gap: 10px; /* Space between buttons */
    justify-content: flex-start; /* Align buttons to the left by default */
    width: 100%; /* Full width */
}

    /* Ensure all buttons inside inv-button-container are the same size */
    .inv-button-container button {
        flex: 1 1 150px; /* All buttons will take equal width and wrap if necessary */
        background: linear-gradient(135deg, #28a745, #007bff); /* Green to Blue gradient */
        color: white;
        border: none;
        border-radius: 5px;
        padding: 12px 24px;
        font-size: 16px;
        cursor: pointer;
        transition: all 0.3s ease;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
        text-align: center;
        margin-bottom: 10px; /* Space below each button */
    }

        /* Hover effect for buttons inside inv-button-container */
        .inv-button-container button:hover {
            background: linear-gradient(135deg, #218838, #0056b3);
            transform: scale(1.05);
            box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
        }

        /* Focus effect for accessibility */
        .inv-button-container button:focus {
            outline: none;
            box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5); /* Blue outline when focused */
        }

/* Separator line styling */
.inv-separator {
    width: 100%;
    height: 2px;
    background: linear-gradient(135deg, #28a745, #007bff); /* Same gradient */
    margin: 20px 0;
}

/* Add New Item button styling */
.inv-add-button-container {
    display: flex;
    justify-content: center; /* Center the button horizontally */
    margin-top: 20px; /* Add space above the button */
}

.inv-addform-button {
    background: linear-gradient(135deg, #28a745, #007bff);
    color: white;
    border: none;
    border-radius: 5px;
    padding: 12px 24px;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 300px; /* Fixed width for consistency */
    text-align: center;
}

    /* Hover effect for the Add New Item button */
    .inv-addform-button:hover {
        background: linear-gradient(135deg, #218838, #0056b3);
        transform: scale(1.05);
        box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
    }

/* Responsive styles for phones */
@media (max-width: 1024px) {
    .card-container {
        grid-template-columns: repeat(3, 1fr); /* 3 cards per row on medium screens */
    }
}

@media (max-width: 768px) {
    .card-container {
        grid-template-columns: repeat(2, 1fr); /* 2 cards per row on smaller screens */
    }

    .inv-button-container {
        padding: 0 20px; /* Increase padding for smaller screens */
    }

        .inv-button-container button {
            width: 200px; /* Decrease button width */
            font-size: 14px; /* Smaller text size */
            padding: 10px 20px; /* Adjust padding */
        }

    .inv-separator {
        margin: 15px 0; /* Reduce space around the separator */
    }

    .inv-settings-dropdown button {
        width: 180px; /* Narrow down dropdown button width */
    }

    .cases-input {
        font-size: 20px; /* Larger text size */
        padding: 18px; /* More padding for larger click area */
    }

        /* Increase the size of the stepper arrows */
        .cases-input::-webkit-outer-spin-button,
        .cases-input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
            height: 30px;
            width: 30px;
        }

        .cases-input[type="number"] {
            -moz-appearance: textfield;
        }
}

@media (max-width: 480px) {
    p {
        text-align: center;
        font-size: 1.2em;
        margin-bottom: 2px;
    }
    .invsubmit-button {
        width: -webkit-fill-available;
        position: relative;
        background: linear-gradient(135deg, #28a745, #007bff); /* Green to Blue gradient */
        color: white; /* White text for contrast */
        border: none; /* No border */
        border-radius: 5px; /* Rounded corners */
        padding: 10px 20px; /* Larger padding to make button bigger */
        font-size: 12px; /* Increase font size */
        cursor: pointer; /* Pointer cursor on hover */
        transition: all 0.3s ease; /* Smooth transition for hover effects */
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
        overflow: hidden; /* Ensure glitter stays within the button */
    }
    .card-container {
        display: flex;
        flex-wrap: wrap; /* Allows cards to wrap onto a new line */
        gap: 10px; /* Adds space between the cards */
        grid-template-columns: 1fr; /* 1 card per row on very small screens */
    }
    .card {
        padding: 15px;
        margin: 0;
        border-radius: 5px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        background-color: white;
        height: auto; /* Let the card height adjust based on content */
        overflow: hidden;
        font-size: 1rem;
        justify-content: space-between;
        flex: 1 1 calc(60% - 10px); /* This ensures the cards take up 50% of the container width minus the gap */
        box-sizing: border-box;
    }
    .inv-button-container {
        padding: 0 10px; /* Add padding on mobile */
    }

        .inv-button-container button {
            width: 100%; /* Make buttons full width */
            font-size: 14px; /* Adjust font size */
            padding: 10px; /* Smaller padding for mobile */
        }

    .inv-separator {
        margin: 10px 0; /* Further reduce space */
    }

    .inv-settings-dropdown button {
        width: 100%; /* Full width for dropdown buttons */
    }
}

.form-group {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin: 20px auto;
    max-width: 1000px;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

label {
    font-size: 16px;
    font-weight: 600;
    color: #333;
    margin-bottom: 10px;
}

.cases-input {
    width: 100%;
    padding: 14px;
    font-size: 16px;
    border-radius: 8px;
    border: 2px solid #ccc;
    background-color: #fff;
    box-sizing: border-box;
    appearance: none; /* Removes default browser styling */
    transition: border-color 0.3s, background-color 0.3s ease;
}

    /* Focus effect for the number input */
    .cases-input:focus {
        border-color: #007bff;
        background-color: #f0f8ff; /* Light blue background on focus */
        outline: none;
    }

input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
}

.buttoninv-container {
    display: flex;
    justify-content: space-evenly; /* Center buttons horizontally */
    flex-wrap: wrap; /* Allow buttons to wrap */
    margin: 5px 0; /* Margin around the container */
}

/* Wrapper for each button */
.button-wrapper {
    margin: 0 5px; /* Space between buttons */
}

/* Style for each button */
.inventory-button {
    padding: 15px 35px; /* Equal padding to keep buttons same size */
    border: none; /* Remove border */
    border-radius: 5px; /* Rounded corners */
    background: linear-gradient(135deg, #28a745, #007bff); /* Button color */
    color: white; /* Text color */
    font-size: 16px; /* Font size */
    cursor: pointer; /* Pointer cursor */
    transition: all 0.2s ease; /* Smooth transition for hover and click */
    display: inline-block;
    box-sizing: border-box;
    text-align: center; /* Center text */
}

    /* Hover effect */
    .inventory-button:hover {
        background-color: #0056b3; /* Darker shade on hover */
    }

    /* Active (clicked) effect - indentation */
    .inventory-button:active {
        transform: translateY(4px); /* Indent when clicked */
    }

    /* Optional: Style for button when clicked (if you want toggling) */
    .inventory-button.clicked {
        transform: translateY(4px); /* Add indentation when 'clicked' class is added */
    }

button {
    padding: 10px 20px; /* Increase button padding */
    background: linear-gradient(135deg, #28a745, #007bff);
    color: white; /* Text color */
    border: none; /* Remove default border */
    border-radius: 5px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
    font-size: 16px; /* Increase font size for better readability */
    font-weight: bold; /* Make text bold */
    text-transform: uppercase; /* Capitalize text */
    transition: background-color 0.3s, box-shadow 0.3s; /* Smooth transition for hover effects */
    margin-right: 10px; /* Add space to the right of the button */
}

    button:hover {
        background-color: #0056b3; /* Darker shade for hover effect */
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add shadow for depth effect */
    }

    button:active {
        background-color: #388e3c; /* Even darker shade for active effect */
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Reduce shadow when button is pressed */
    }

    button:disabled {
        background-color: #a5d6a7; /* Light grey color for disabled state */
        color: #c8e6c9; /* Light text color for disabled state */
        cursor: not-allowed; /* Not-allowed cursor for disabled state */
        opacity: 0.6; /* Reduce opacity for disabled state */
    }

table {
    width: 100%;
    border-collapse: collapse;
    table-layout: auto;
}

table, th, td {
    padding: 8px; /* Add padding for better touch targets */
    text-align: center; /* Align text to the left */
    border: 1px solid black;
}

th, td {
    padding: 10px;
    text-align: left;
}

thead {
    background-color: #f2f2f2;
}

.inventory-system table tr.critical {
    background-color: #f8d7da; /* Red for almost out */
}

.inventory-system table tr.warning {
    background-color: #fff3cd; /* Yellow for low */
}

.inventory-system table tr.normal {
    background-color: #ffffff; /* Default background */
}

.inventory-system .inventory-error {
    color: red;
}

.toggle-button, .export-button {
    background-color: #4CAF50; /* Default button color */
    color: white;
    border: none;
    padding: 10px 15px; /* Adjusted for better touch target */
    font-size: 16px;
    cursor: pointer;
    margin-bottom: 10px;
    border-radius: 4px;
}

.export-button {
    background-color: #007bff; /* Green for export button */
}

.settings-dropdown {
    margin-top: 20px; /* Adjust space above the dropdown */
    background-color: #f0f2f5; /* Light background for dropdown */
    border: 1px solid #ccc; /* Border for dropdown */
    border-radius: 8px; /* Rounded corners for a soft look */
    padding: 20px; /* Add more padding for a spacious feel */
    width: 80%; /* Set a percentage width to allow responsiveness */
    max-width: 600px; /* Max width to prevent it from stretching too much on large screens */
    margin-left: auto;
    margin-right: auto; /* Center horizontally */
    position: relative; /* Allow positioning for the lift effect */
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1); /* Lifting shadow effect */
    transform: translateY(-10px); /* Lift up the dropdown a bit */
    transition: transform 0.3s ease; /* Smooth lift effect */
    z-index: 1000; /* Ensure it is above other content */
}

    /* Add hover effect to lift the dropdown more when hovered */
    .settings-dropdown:hover {
        transform: translateY(-15px); /* Lift the dropdown further on hover */
    }

    /* Layout: Split into two columns using CSS Grid */
    .settings-dropdown .form-group {
        display: grid;
        grid-template-columns: 1fr 1fr; /* Two equal columns */
        gap: 15px; /* Space between the columns */
    }

        /* Ensure the labels and inputs adjust well in the two-column layout */
        .settings-dropdown .form-group label {
            font-weight: bold;
            display: block;
            margin-bottom: 5px;
        }

        .settings-dropdown .form-group input {
            width: 100%;
            padding: 8px;
            font-size: 14px;
            border: 1px solid #ccc;
            border-radius: 4px;
        }

    /* Adjust the button styling */
    .settings-dropdown button {
        background-color: #28a745; /* Green background */
        color: white;
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s ease;
        margin-top: 15px;
        width: 100%;
    }

        /* Hover effect for button */
        .settings-dropdown button:hover {
            background-color: #218838;
        }

/* Make sure the layout is responsive for smaller screens */
@media (max-width: 768px) {
    .settings-dropdown {
        width: 90%; /* Make the dropdown a little wider on smaller screens */
    }

        .settings-dropdown .form-group {
            grid-template-columns: 2fr; /* Stack the form fields vertically on small screens */
        }
}

@media (min-width: 769px) {
    /* General container for the radio button field */
    .form-group {
        display: grid; /* Switch to grid layout for two columns */
        grid-template-columns: 1fr 1fr; /* Two equal columns */
        gap: 20px; /* Space between the columns */
        margin: 20px auto; /* Center horizontally with margin */
        max-width: 1000px; /* Max width to control size */
        padding: 20px;
        background-color: #f9f9f9; /* Light background */
        border-radius: 8px; /* Rounded corners */
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
        transform: translateY(-10px); /* Lift the form group slightly */
        transition: transform 0.3s ease; /* Smooth transition for lift effect */
    }

        /* Lift the form group more on hover */
        .form-group:hover {
            transform: translateY(-15px); /* Lift the form group further when hovered */
        }

        /* Ensure form-group items (labels and inputs) adjust properly */
        .form-group label {
            font-weight: bold;
            display: block;
            margin-bottom: 5px;
        }

        .form-group input {
            width: 100%; /* Ensure inputs fill their container */
            padding: 8px;
            font-size: 14px;
            border: 1px solid #ccc;
            border-radius: 4px;
        }

    .radiofield {
        display: flex;
        flex-direction: column;
        gap: 15px;
        margin: 20px auto;
        max-width: 1000px;
        padding: 20px;
        background-color: #f9f9f9;
        border-radius: 8px;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    }

    .slider-container {
        position: relative;
        width: 100%;
        max-width: 1000px; /* Optional: Limit the slider width */
    }

    .range-labels {
        display: flex;
        justify-content: space-between;
        margin-top: 5px;
    }

    .sliders {
        width: 100%; /* Makes the slider fill the container */
        max-width: 1000px; /* Optionally limit the maximum width */
        height: 30px; /* Optional: Increase height for a thicker slider */
        appearance: none; /* Remove default slider styling */
        background: #ddd; /* Background color for the slider */
        border-radius: 5px; /* Rounded corners for the slider */
        outline: none; /* Remove the outline when focused */
        transition: background 0.3s ease; /* Smooth transition for color changes */
    }

        /* Styling for the slider thumb */
        .sliders::-webkit-slider-thumb {
            appearance: none;
            width: 40px; /* Width of the thumb */
            height: 40px; /* Height of the thumb */
            border-radius: 50%; /* Round thumb */
            background: #4CAF50; /* Thumb color */
            cursor: pointer;
        }

    /* Optional: Change thumb color when hovered */
    .slider::-webkit-slider-thumb:hover {
        background: #45a049;
    }

    /* For Firefox */
    .sliders::-moz-range-thumb {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: #4CAF50;
        cursor: pointer;
    }

    .slider-output {
        margin-top: 10px;
        text-align: center;
        font-weight: bold;
        font-size: 18px;
    }
    /* Styling for the radio container */
    .radio-container {
        gap: 20px; /* Slightly larger gap between radio options */
    }

    /* Each radio item styling */
    .radio-item {
        font-size: 16px; /* Larger font size for desktop */
        padding: 10px;
        border-radius: 8px;
        transition: transform 0.3s ease, background-color 0.3s ease;
        position: relative;
    }

        /* Hide radio buttons on desktop but keep labels visible */
        .radio-item input[type="radio"] {
            visibility: hidden; /* Hide radio button itself */
            position: absolute; /* Remove radio button from layout flow */
        }

        /* Hover effect on radio items on desktop */
        .radio-item:hover {
            background-color: #007bff;
            color: #fff;
            transform: scale(1.05); /* Slight scaling on hover for desktop */
        }

        /* Label styling */
        .radio-item label {
            cursor: pointer;
            display: block;
            padding-left: 30px; /* Space for the invisible radio button */
            position: relative;
            font-size: 16px;
            color: #555;
            transition: color 0.3s ease;
        }

        /* Add a custom "dot" effect for the label (instead of radio button) */
        .radio-item input[type="radio"]:checked + label::before {
            content: '';
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 18px;
            height: 18px;
            border-radius: 50%;
            background-color: #007bff;
        }

        /* Focus effect for labels when clicking */
        .radio-item label:hover {
            color: #007bff;
        }
}
/* Responsive design for smaller screens */
@media (max-width: 600px) {
    
    .inventory-system {
        width: 95%; /* Increase width on smaller screens */
        padding: 10px; /* Reduce padding for better fit */
    }

    .settings-dropdown {
        width: 90%; /* Make the width more flexible on small screens */
        max-width: 320px; /* Limit maximum width */
        margin-left: auto; /* Center horizontally */
        margin-right: auto; /* Center horizontally */
    }

    .buttoninv-container {
        flex-direction: column; /* Stack buttons in a column on small screens */
        align-items: stretch; /* Allow buttons to stretch to full width */
    }

    table {
        width: 100%; /* Ensure table takes full width */
        overflow-x: auto; /* Allow horizontal scrolling if necessary */
        display: block; /* Use block to enable scrolling */
    }

    th, td {
        white-space: nowrap; /* Prevent text from wrapping */
    }

    /* Optional: To improve visibility on mobile, adjust font size */
    th, td {
        font-size: 7px; /* Adjust as needed */
    }

    form {
        width: 100%; /* Make the form take full width on small screens */
        padding: 15px; /* Adjust padding for smaller screens */
    }

    .sliders {
        width: 100%; /* Makes the slider fill the container */
        max-width: 1000px; /* Optionally limit the maximum width */
        height: 30px; /* Optional: Increase height for a thicker slider */
        appearance: none; /* Remove default slider styling */
        background: #ddd; /* Background color for the slider */
        border-radius: 5px; /* Rounded corners for the slider */
        outline: none; /* Remove the outline when focused */
        transition: background 0.3s ease; /* Smooth transition for color changes */
    }

        .sliders::-webkit-slider-thumb {
            appearance: none;
            width: 40px; /* Width of the thumb */
            height: 40px; /* Height of the thumb */
            border-radius: 50%; /* Round thumb */
            background: #4CAF50; /* Thumb color */
            cursor: pointer;
        }

    .slider-output {
        margin-top: 10px;
        text-align: center;
        font-weight: bold;
        font-size: 18px;
    }

    button {
        width: 100%; /* Make button full width on smaller screens */
        margin-right: 0; /* Remove right margin for full-width button */
        margin-bottom: 10px; /* Space below the button */
    }

    .settings-dropdown {
        width: 100%; /* Change to full width for responsive design */
    }

    .modal {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.5);
    }

    .modalContent {
        background-color: white;
        padding: 20px;
        text-align: center;
        border-radius: 10px;
    }

    .loadingMessage {
        font-size: 16px;
    }

    .error {
        color: red;
        font-size: 14px;
    }

    .productInfo {
        margin-top: 20px;
    }
    /* Style for the field container */
    .radiofield {
        display: flex;
        flex-direction: column;
        gap: 15px;
        margin: 20px auto;
        max-width: 600px;
        padding: 20px;
        background-color: #f9f9f9;
        border-radius: 8px;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    }

    .radiolabel {
        font-size: 16px;
        font-weight: 600;
        color: #333;
        margin-bottom: 10px;
    }

    .radio-container {
        display: flex;
        flex-direction: column;
        gap: 15px; /* Spacing between radio items */
    }

    /* Styling for each radio button item */
    .radio-item {
        display: flex;
        align-items: center;
        gap: 12px; /* Space between radio button and label */
        font-size: 14px;
        color: #555;
        cursor: pointer;
        transition: background-color 0.3s ease;
    }

        .radio-item:hover {
            background-color: #f1f1f1;
            border-radius: 8px;
        }

        /* Customize radio button appearance */
        .radio-item input[type="radio"] {
            width: 18px;
            height: 18px;
            border-radius: 50%;
            border: 2px solid #ccc;
            background-color: #fff;
            appearance: none;
            outline: none;
            transition: border-color 0.3s, background-color 0.3s;
        }

            .radio-item input[type="radio"]:checked {
                background-color: #007bff;
                border-color: #007bff;
            }

                .radio-item input[type="radio"]:checked::before {
                    content: '';
                    display: block;
                    position: absolute;
                    width: 8px;
                    height: 8px;
                    background-color: #fff;
                    border-radius: 50%;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }

            .radio-item input[type="radio"]:focus {
                border-color: #007bff;
                box-shadow: 0 0 5px rgba(0, 123, 255, 0.4);
            }

        /* Styling for the radio button labels */
        .radio-item label {
            cursor: pointer;
            transition: color 0.3s ease;
        }

            /* Hover effect for labels */
            .radio-item label:hover {
                color: #007bff;
            }

        /* Focus effect for radio buttons */
        .radio-item input[type="radio"]:focus {
            border-color: #007bff;
            box-shadow: 0 0 5px rgba(0, 123, 255, 0.4);
        }
}