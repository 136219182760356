.privacy-policy-container {
    padding: 20px;
    max-width: 1000px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
}

    .privacy-policy-container h1 {
        font-size: 2.5rem;
        text-align: center;
        margin-bottom: 20px;
    }

    .privacy-policy-container h2 {
        font-size: 1.8rem;
        margin-top: 30px;
        color: #333;
    }

    .privacy-policy-container p,
    .privacy-policy-container ul {
        font-size: 1rem;
        line-height: 1.6;
        color: #555;
    }

    .privacy-policy-container ul {
        list-style-type: none;
        padding: 0;
    }

        .privacy-policy-container ul li {
            margin-bottom: 10px;
        }
