/* Landing Container */
.landing-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
    width: 100%;
    background-color: #f9fafb; /* Softer background color */
    padding: 0rem 0rem;
    text-align: center;
    position: relative;
    overflow: hidden;
}

/* Title Styles */
.landing-title {
    font-size: 3rem;
    color: #2c3e50; /* Darker shade of blue */
    font-weight: 700;
    margin-bottom: 1rem;
    line-height: 1.2;
    letter-spacing: 1px; /* Slight letter spacing for readability */
}

/* Description Styles */
.landing-description {
    font-size: 1.3rem;
    color: #34495e; /* Darker gray-blue */
    margin-bottom: 2rem;
    max-width: 700px;
    line-height: 1.7; /* Better line-height for readability */
    margin-left: auto;
    margin-right: auto;
    font-family: 'Roboto', sans-serif; /* Use modern sans-serif font */
}

/* Steps Section Styles */
.landing-steps {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center content vertically */
    align-items: center; /* Center content horizontally */
    background-color: #ecf0f1; /* Light gray background */
    padding: 1.5rem;
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Light shadow */
    margin-bottom: 2rem;
    width: 100%;
    max-width: 800px; /* Control the width */
    margin-left: auto; /* Center horizontally */
    margin-right: auto; /* Center horizontally */
}

/* Steps Title Styles */
.steps-title {
    font-size: 1.7rem;
    color: #2980b9; /* Vibrant blue */
    margin-bottom: 1.5rem;
    font-weight: 700;
    text-transform: uppercase; /* Uppercase for emphasis */
}

/* Steps List Styles */
.steps-list {
    list-style-type: none; /* Remove bullet points */
    padding: 0;
    font-size: 1.1rem;
    color: #2c3e50;
    max-width: 600px;
    margin-left: auto; /* Center horizontally */
    margin-right: auto; /* Center horizontally */
    display: flex;
    flex-direction: column; /* Stack list items vertically */
    align-items: center; /* Center items horizontally */
    text-align: center; /* Center the text inside the list items */
    justify-content: center; /* Vertically center items */
}

    /* List Item Styles */
    .steps-list li {
        margin-bottom: 0.5rem;
    }

/* Call-to-Action Button Styles */
.cta-button {
    padding: 12px 24px;
    background: linear-gradient(135deg, #3498db, #2ecc71); /* Gradient background */
    color: white;
    border: none;
    cursor: pointer;
    font-size: 1.2rem;
    border-radius: 8px;
    transition: background 0.3s, transform 0.3s ease, box-shadow 0.3s ease; /* Added shadow transition */
    text-decoration: none;
    display: inline-block;
}

    .cta-button:hover {
        background: linear-gradient(135deg, #2980b9, #27ae60); /* Darker gradient on hover */
        transform: scale(1.05); /* Slightly enlarge on hover */
        box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2); /* Subtle shadow on hover */
    }

.footer {
    background-color: #FF6600; /* Orange background */
    color: white;
    padding: 40px 20px;
    text-align: center;
    position: relative;
    width: -webkit-fill-available;
}

.footer-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-bottom: 20px;
    max-width: 1200px; /* Optional, to limit max width */
    margin: 0; /* Center footer-content */
}

.footer-logo {
    flex: 0 0 auto; /* Ensure the logo stays in place and doesn't shrink or stretch */
}

    .footer-logo img {
        width: 150px; /* Adjust logo size */
    }

.footer-links, .footer-social {
    display: flex;
    flex-direction: row; /* Arrange items horizontally */
    align-items: flex-start;
}

    .footer-links ul {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex; /* Align links in a row */
    }

        .footer-links ul li {
            margin: 0 15px; /* Space between links */
        }

    .footer-links a {
        color: white;
        text-decoration: none;
        font-size: 16px;
    }

        .footer-links a:hover {
            text-decoration: underline;
        }

.footer-social {
    margin-left: 30px; /* Space between links and social media */
}

    .footer-social a {
        color: white;
        margin: 0 10px;
        text-decoration: none;
    }

        .footer-social a:hover {
            color: #FFC107; /* Hover effect */
        }

.footer-bottom {
    position: absolute;
    bottom: 30px;
    width: 100%;
    text-align: center;
    padding-top: 20px;
}

    .footer-bottom p {
        font-size: 14px;
        margin: 0;
    }

@media (max-width: 768px) {
    .landing-title {
        font-size: 2rem; /* Adjust font size for smaller screens */
    }

    .footer {
        width: auto;
    }

    .footer-bottom {
        bottom: 10px;
    }

    .footer-content {
        flex-direction: column;
        align-items: center;
    }

    .landing-description {
        font-size: 1.1rem;
    }

    .steps-list {
        font-size: 1rem;
    }

    .cta-button {
        font-size: 1rem;
        padding: 10px 20px;
    }
}

/* Slider Styles */
.sliderhome {
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-bottom: 3rem; /* Space between slider and content */
}

.slides {
    display: flex;
    transition: transform 0.5s ease-in-out;
    padding-top: 0;
    padding-bottom: 0;
}

.slide {
    min-width: 100%;
    position: relative;
    /* Maintain 716x415 aspect ratio */
    aspect-ratio: 716 / 190;
    box-sizing: border-box;
}

    .slide img {
        width: 100%;
        height: auto; /* Ensure the image fills the container */
        object-fit: contain; /* Ensure the image covers the area while maintaining its aspect ratio */
        display: block;
        border-radius: 10px; /* Round the corners of images for a smoother look */
    }

/* Optional: Add an overlay for the slider images */
.sliderhome .slide img::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3); /* Subtle dark overlay */
    border-radius: 10px; /* Match image corners */
}