.contact-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
}

h1 {
    text-align: center;
    font-size: 2em;
    margin-bottom: 20px;
}

.contact-p {
    text-align: center;
    font-size: 1.2em;
    margin-bottom: 10px;
}

.form-group {
    margin-bottom: 15px;
}

label {
    display: block;
    font-size: 1.1em;
    margin-bottom: 5px;
}

input, textarea {
    width: 100%;
    padding: 10px;
    font-size: 1em;
    border: 1px solid #ccc;
    border-radius: 5px;
}
.success-message {
    color: green;
    font-weight: bold;
}

.error-message {
    color: red;
    font-weight: bold;
}
textarea {
    height: 150px;
}

.buttoncontactpage {
    background-color: #007BFF;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 1.1em;
    cursor: pointer;
}

    .buttoncontactpage:hover {
        background-color: #0056b3;
    }
