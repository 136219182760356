/* Create Account Container */
.create-account-container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center items horizontally */
    justify-content: center; /* Center items vertically */
    height: 80vh; /* Full height */
    background-color: #f9f9f9;
    padding: 2rem;
}

/* Title Styles */
.create-account-title {
    font-size: 2.5rem;
    color: #333; /* Darker text color */
    margin-bottom: 1rem;
    text-align: center; /* Center the title text */
}

/* Form Styles */
.create-account-form {
    background-color: #ffffff; /* White background for the form */
    padding: 2rem;
    gap: 0.5rem;
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    width: 100%; /* Full width */
    max-width: 400px; /* Max width for better presentation */
    display: flex; /* Flex container for the form */
    flex-direction: column; /* Stack elements vertically */
}

/* Form Control Styles */
.form-control-form-group {
    margin-bottom: 1rem; /* Space between form groups */
    width: 100%; /* Full width */
}

.form-group label {
    display: block;
    font-size: 1rem;
    margin-bottom: 0.5rem; /* Space below the label */
}

/* Input Styles */
.create-account-form-control {
    width: 100%; /* Full width */
    padding: 0.75rem; /* Padding inside the input */
    border: 1px solid #ddd; /* Light border */
    border-radius: 4px; /* Rounded corners */
    font-size: 1rem; /* Font size for inputs */
}

/* Button Styles */
.create-account-button {
    width: 100%; /* Full width */
    padding: 0.75rem; /* Padding inside the button */
    background-color: #007bff; /* Primary button color */
    border: none; /* No border */
    border-radius: 4px; /* Rounded corners */
    color: white; /* Text color */
    font-size: 1rem; /* Font size */
    cursor: pointer; /* Pointer cursor */
    transition: background-color 0.2s; /* Smooth transition for hover */
}

    .create-account-button:hover {
        background-color: #0056b3; /* Darker color on hover */
    }

/* Error Message Styles */
.create-account-error {
    color: #e74c3c; /* Error color */
    margin-bottom: 1rem; /* Space below error message */
}

/* Password Strength Meter Styles */
.password-strength-meter {
    margin-top: 10px; /* Space above strength meter */
}

/* Strength Bar Styles */
.strength-bar {
    height: 5px; /* Height of the strength bar */
    border-radius: 3px; /* Rounded corners */
    background-color: #e0e0e0; /* Default background */
    position: relative; /* Positioning for transitions */
    transition: width 0.5s ease; /* Smooth width transition */
}

    .strength-bar.weak {
        width: 33%; /* Weak strength */
        background-color: red; /* Color for weak strength */
    }

    .strength-bar.moderate {
        width: 66%; /* Moderate strength */
        background-color: orange; /* Color for moderate strength */
    }

    .strength-bar.strong {
        width: 100%; /* Strong strength */
        background-color: yellowgreen; /* Color for strong strength */
    }

    .strength-bar.very-strong {
        width: 100%; /* Very strong strength */
        background-color: green; /* Color for very strong strength */
    }

/* Password Strength Text Styles */
.password-strength-meter p {
    margin-top: 5px; /* Space above the text */
    text-align: center; /* Center the strength text */
}