.DashBoard-system {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    background-color: #f4f4f9;
}

    /* Style for the dashboard header */
    .DashBoard-system h1 {
        color: #333;
        font-size: 36px;
    }

    /* Style for the user email display */
    .DashBoard-system p {
        font-size: 20px;
        color: #555;
        margin-bottom: 20px;
    }

    /* Style for the logout button */
    .DashBoard-system button {
        background-color: #e74c3c;
        color: white;
        padding: 10px 30px;
        font-size: 18px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s;
        margin-bottom: 20px; /* Add spacing below the buttons */
    }

        .DashBoard-system button:hover {
            background-color: #c0392b;
        }

/* Center the buttons container */
.inv-button-container {
    display: flex;
    gap: 20px;
}

/* Button styles */
.inv-foodwaste-button, .inv-analysis-button {
    position: relative;
    background: linear-gradient(135deg, #28a745, #007bff); /* Green to Blue gradient */
    color: white;
    border: none;
    border-radius: 5px;
    padding: 20px 40px;
    font-size: 24px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

    /* Glitter Effect */
    .inv-foodwaste-button::before,
    .inv-foodwaste-button::after,
    .inv-foodwaste-button .glitter-1,
    .inv-foodwaste-button .glitter-2,
    .inv-foodwaste-button .glitter-3,
    .inv-foodwaste-button .glitter-4,
    .inv-analysis-button::before,
    .inv-analysis-button::after,
    .inv-analysis-button .glitter-1,
    .inv-analysis-button .glitter-2,
    .inv-analysis-button .glitter-3,
    .inv-analysis-button .glitter-4 {
        content: '';
        position: absolute;
        background: radial-gradient(circle, rgba(255, 255, 255, 0.8) 20%, rgba(255, 255, 255, 0) 50%);
        border-radius: 50%;
        width: 15px;
        height: 15px;
        animation: glitter 1.5s linear infinite;
        pointer-events: none;
    }

    /* Glitter Positioning */
    .inv-foodwaste-button::before {
        top: 10%;
        left: 15%;
        animation-delay: 0s;
    }

    .inv-foodwaste-button::after {
        top: 40%;
        left: 60%;
        animation-delay: 0.5s;
    }

    .inv-foodwaste-button .glitter-1 {
        top: 30%;
        left: 25%;
        animation-delay: 0.10s;
    }

    .inv-foodwaste-button .glitter-2 {
        top: 70%;
        left: 75%;
        animation-delay: 0.30s;
    }

    .inv-foodwaste-button .glitter-3 {
        top: 20%;
        left: 80%;
        animation-delay: 0.50s;
    }

    .inv-foodwaste-button .glitter-4 {
        top: 50%;
        left: 20%;
        animation-delay: 1s;
    }

/* Animation for glitter */
@keyframes glitter {
    0% {
        transform: scale(0.5) translateX(0) translateY(0);
        opacity: 1;
    }

    50% {
        transform: scale(1) translateX(30px) translateY(-30px);
        opacity: 0.7;
    }

    100% {
        transform: scale(0.5) translateX(0) translateY(0);
        opacity: 1;
    }
}

/* Hover effect */
.inv-foodwaste-button:hover, .inv-analysis-button:hover {
    background: linear-gradient(135deg, #218838, #0056b3);
    transform: scale(1.05);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
}

/* Focus effect */
.inv-foodwaste-button:focus, .inv-analysis-button:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
}
.items-table {
    overflow-x: auto;
    margin: 20px 0;
}

table {
    width: 100%;
    border-collapse: collapse;
}

th, td {
    padding: 5px;
    text-align: left;
    border: 1px solid #ddd;
}

thead {
    background-color: #f4f4f4;
}
.alert-count {
    background-color: red; /* Red background */
    color: white; /* White text color */
    border-radius: 50%; /* Make it a circle */
    padding: 0.2em 0.5em; /* Space around the number */
    font-weight: bold; /* Make the number bold */
    margin-left: 0.5em; /* Space between the text and the circle */
}
@media (max-width: 768px) {
    .DashBoard-system {
        height: 80vh;
    }
    .items-table {
        overflow-x: auto; /* Enable horizontal scrolling */
        margin: 0 auto;
    }

    table {
        border-collapse: separate; /* Allow space between columns */
        border-spacing: 2px; /* Adjust the space between columns (you can change 5px to your desired value) */
        width: 100%; /* Ensures table takes up full width */
        border-spacing: 2px; /* Ensures no space between cells */
       
    }

    th, td {

        overflow: hidden; /* Hide any text that overflows beyond the cell */
        text-overflow: ellipsis; /* Add ellipsis (...) when text overflows */
        column-width: 10px;
        padding: 0 !important;
        padding-right: -1px; /* Removes all padding from td and th */
        font-size: 7px; /* Reduces font size for smaller screens */
        text-align: center; /* Centers the text inside the table cells */
        margin: 0 !important; /* Removes any margin */
        border: none; /* Optionally remove border (optional) */
    }

    th {
        background-color: #f4f4f4;
    }

    td {
        border-top: 1px solid #ddd; /* Border between rows */
    }

    /* Optional: Remove space between the table and its container */
    .items-table table {
        margin: 0; /* Remove margin */
    }
}