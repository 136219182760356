.subscription-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 700px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
}

.subscription-title {
    text-align: center;
    color: #333;
    font-size: 24px;
    margin-bottom: 20px;
}

.subscription-error {
    color: red;
    text-align: center;
    margin-bottom: 15px;
}

.subscription-form {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.subscription-form-group {
    margin-bottom: 15px;
}

    .subscription-form-group label {
        margin-bottom: 5px;
        font-weight: bold;
        color: #555;
    }

.form-control {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    width: 100%;
}

    .form-control:focus {
        border-color: #4CAF50; /* Change border color on focus */
        outline: none;
    }

.subscription-button {
    padding: 10px;
    background-color: #4CAF50; /* Primary button color */
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    transition: background-color 0.3s;
    margin-top: 10px; /* Space between form fields and button */
    width: 100%; /* Make the button full width on mobile */
}

    .subscription-button:hover {
        background-color: #45a049; /* Darker shade on hover */
    }

/* Media query for smaller screens */
@media (max-width: 600px) {
    .subscription-container {
        padding: 15px;
    }

    .subscription-title {
        font-size: 20px;
    }

    .form-control {
        font-size: 14px;
    }

    .subscription-button {
        font-size: 14px;
    }
}

/* Dashboard System Style Updates */

.subscription-system {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    background-color: #f4f4f9;
}

    /* Dashboard Header */
    .subscription-system h1 {
        color: #333;
        font-size: 36px;
    }

    /* User Email */
    .subscription-system p {
        font-size: 20px;
        color: #555;
        margin-bottom: 20px;
    }

    /* Logout Button */
    .subscription-system button {
        background-color: #e74c3c;
        color: white;
        padding: 10px 30px;
        font-size: 18px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s;
        margin-bottom: 20px;
    }

        .subscription-system button:hover {
            background-color: #c0392b;
        }

.subscription-button-container {
    display: flex;
    gap: 20px;
}

/* Table Styling */
.items-table {
    overflow-x: auto;
    margin: 20px 0;
}

table {
    width: 100%;
    border-collapse: collapse;
}

th, td {
    padding: 5px;
    text-align: left;
    border: 1px solid #ddd;
}

thead {
    background-color: #f4f4f4;
}

.alert-count {
    background-color: red;
    color: white;
    border-radius: 50%;
    padding: 0.2em 0.5em;
    font-weight: bold;
    margin-left: 0.5em;
}

@media (max-width: 768px) {
    .subscription-system {
        height: 80vh;
    }
}
